<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
		<path
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			d="M1 13 13 1M1 1l12 12"
		/>
	</svg>
</template>
